<template>
  <v-card elevation="2" class="pa-6 mt-10">
    <v-card-title>
      Submitted Applications
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="applicants"
      :search="search"
      class="elevation-1"
    >
      <template #item.googleScholar="{ value }">
        <a target="_blank" :href="value">
          View Profile
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "applicantId" },
        { text: "University", value: "university" },
        { text: "Class Obtained", value: "classObtained" },
        { text: "Subject Area", value: "subjectArea" },
        { text: "Effective Date", value: "effectiveDate" },
        { text: "Scholar URL", value: "googleScholar" },
      ],
      applicants: [],
    };
  },
  mounted() {
    this.getApplicants();
  },
  methods: {
    getApplicants() {
      axios.get("http://localhost:8091/api/v1/applicants").then((response) => {
        this.applicants = response.data;
        console.log(this.applicants);
      });
    },
  },
};
</script>
